// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/routes/_auth.tsx"
);
import.meta.hot.lastModified = "1735653278592.8333";
}
// REMIX HMR END

import { Outlet, useLoaderData, useOutletContext } from '@remix-run/react'
import { json, LoaderFunctionArgs } from '@remix-run/server-runtime'
import { adminSettings } from '~admin/providers/global-settings/provider'
import { ReCaptchaProvider } from '~common/components/recaptcha/ReCaptcha'
import { UnwrapLoaderData } from '~common/types'

export async function loader({ request }: LoaderFunctionArgs) {
  const { settings } = await adminSettings({ request })
  const { recaptchaPublic } = settings.globalSettings ?? {}

  let publicKey = recaptchaPublic
  if (!publicKey?.length) {
    publicKey = undefined
  }
  return json({ reCaptchaPublicKey: publicKey })
}

export default function authRoot() {
  const { reCaptchaPublicKey } = useLoaderData() as UnwrapLoaderData<
    typeof loader
  >
  const outletContext = useOutletContext<any>()
  return (
    <ReCaptchaProvider publicKey={reCaptchaPublicKey}>
      <Outlet context={outletContext} />
    </ReCaptchaProvider>
  )
}
